import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { money } from '../../utils/utils';
import { appContext } from '../contexts';

interface ICelebrationToastProps {
    dismiss: () => void;
    style?: React.CSSProperties;
    multiplier: number;
    win: number;
}

export default (props: ICelebrationToastProps) => {
    const app = useContext(appContext);
    const { t } = useTranslation();

    return (
        <div id="cashed-out" className="" style={props.style}>
            <div className="flex flex-row items-center place-content-stretch gap-2 px-2 py-1 rounded-full bg-lime-950  border border-lime-700">
                <div className="flex flex-col items-center px-3">
                    <div className="flex flex-nowrap whitespace-nowrap text-xs text-gray-400 leading-5">
                        {t('plot.youHaveCashedOut')}!
                    </div>
                    <div className="text-xl leading-5">{props.multiplier.toFixed(2)}x</div>
                </div>
                <div className="flex flex-col items-center place-content-stretch px-8 py-0 rounded-full font-semibold bg-lime-600 border border-lime-700 ">
                    <div className="flex flex-nowrap text-sm items-center">
                        <span className="">{t('plot.win')}, </span>
                        <span className="text-base">{app.config.bet.code}</span>
                    </div>
                    <div className="text-xl">
                        <span className="">{money(props.win, app.config.bet)}</span>
                    </div>
                </div>
                <button className="flex flex-row text-gray-400">
                    <span className="text-xl" onClick={props.dismiss}>
                        ×
                    </span>
                </button>
            </div>
        </div>
    );
};
