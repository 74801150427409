import { useContext, useState } from 'react';
import { appContext } from './contexts';
import { useEventify } from './utils';

export const PingIndicator = () => {
    const app = useContext(appContext);
    const [pingState, setPingState] = useState(app.ping);

    useEventify((eventify) => {
        eventify.listenTo(app, 'ping', (ping) => {
            setPingState(ping);
        });
    });

    return <span className="text-[#a8a29e]">{pingState.toFixed(0)} ms</span>;
};
