import { IAutoplayConfig, IBetConfig, ICalculatorConfig, IServerConfig } from './ConfigTypes';
import { IParticipation, IRoundSource } from './Types';
import { IBetBasicEntry } from './TypesBetboard';
export type MultiplierValue = number;
export type ErrorMessage = string;

export enum ClientPlayMode {
    normal = 'normal',
    demo = 'demo'
}

// export interface IParticipant {
//     id: string;
//     name: string;
//     bet: number;
//     date: number;
//     round: number;
//     roundId: string;
// }

interface IBetWinEvent {
    id: number;
    slot: number;
    bet: number;
    win: number;
    multiplier: MultiplierValue;
}

export type THistoryRoundMultipliersEntry = { multiplier: number; id: string };

export const enum MessageTypes {
    msg = 'msg', // client to server
    event = 'event', // server to client
    rpc = 'rpc', // client to server with callback
    ret = 'ret' // server callback
}

export const enum ClientMessageType {
    ping = 'ping',
    auth = 'auth',
    bet = 'bet',
    betProceeded = 'betProceeded',
    cashOut = 'cashOut',
    CashOutProceeded = 'cashOutProceeded',
    roundInfo = 'roundInfo',
    roundResult = 'roundResult',
    getUserParticipations = 'getUserParticipations',
    getTopBets = 'getTopBets'
}

export interface IPingReq extends IClientMessage {
    type: ClientMessageType.ping;
    callback: string;
    [x: string]: unknown;
}

export interface Message<T = IClientMessage | IServerMessage | null> {
    m: MessageTypes;
    errorMessage?: ErrorMessage;
    callback?: string;
    data: T;
}

// Client - Server
export interface IClientMessage {
    type: ClientMessageType;
}

export interface IClientAuthReq extends IClientMessage {
    type: ClientMessageType.auth;
    sessionId: string;
    currency: string;
    mode: ClientPlayMode;
    cheatBalance: number;
}

export interface IClientBetReq extends IClientMessage {
    type: ClientMessageType.bet;
    slot: number;
    amount: number;
    autoCashOutMultiplier: number;
    clientSeed: string;
}

export interface IClientCashoutReq extends IClientMessage {
    type: ClientMessageType.cashOut;
    slot: number;
}

export interface IClientRoundResultReq extends IClientMessage {
    type: ClientMessageType.roundResult;
    roundId: number;
}
export interface IClientUserParticipationsReq extends IClientMessage {
    type: ClientMessageType.getUserParticipations;
}
export interface IClientgetTopBetsReq extends IClientMessage {
    type: ClientMessageType.getTopBets;
    period: PeriodArgument;
    by: ByArgument;
}

export enum PeriodArgument {
    day = 'day',
    month = 'month',
    year = 'year',
    all = 'all'
}
export enum ByArgument {
    wins = 'wins',
    multipliers = 'multipliers'
}

export interface IClientRoundInfoReq extends IClientMessage {
    type: ClientMessageType.roundInfo;
    roundId: string;
}

// Server - Client

export enum ServerMessageType {
    mphistory = 'mphistory',
    none = 'none',
    pong = 'pong',
    auth = 'auth',
    config = 'config',
    playerState = 'playerState',
    serverState = 'serverState',
    win = 'win',
    betlog = 'betlog',
    bet = 'BET',
    betProceeded = 'BET_PROCEEDED',
    betCancel = 'CANCEL_BET',
    betCancelProceeded = 'CANCEL_BET_PROCEEDED',
    cashOutProceeded = 'CASHOUT_PROCEEDED',
    cashOut = 'CASHOUT',
    roundResult = 'roundResult',
    participations = 'participations',
    json = 'json',
    cashOutEvent = 'cashOutEvent'
}

export interface IServerMessage {
    type: ServerMessageType;
    error?: number;
    errorMessage?: ErrorMessage;
}

export interface IServerPlayerState extends IServerMessage {
    type: ServerMessageType.playerState;
    balance: number;
    mode: ClientPlayMode;
    name: string;
}

// export interface IServerBetWinEvent extends IBetWinEvent {
//     type: ServerMessageType.win;
//     balance: number;
// }

export interface IServerBetEvent extends IServerMessage {
    type: ServerMessageType.bet;
    data: IBetBasicEntry[];
}

export interface IServerCashOutMessage extends IServerMessage {
    type: ServerMessageType.cashOutEvent;
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventData: any;
}

export interface IServerBetMessage extends IServerMessage {
    type: ServerMessageType.bet;
    eventData: {
        balance: number;
        betId: string;
        roundId: string;
        index: 0 | 1; // bet button index
        timestamp: number;
    };
}

export interface IServerBetProceededMessage extends IServerMessage {
    type: ServerMessageType.betProceeded;
    eventData: {
        username: string;
        amount: number;
        userId: string;
        betId: string;
        multiplier: 0;
        win: 0;
        avatarId: string;
    };
}

export interface IServerBetCancelMessage extends IServerMessage {
    type: ServerMessageType.betCancel;
    eventData: {
        balance: number;
        roundId?: string;
        index: 0 | 1; // bet button index
        token: string;
        betId: string;
        timestamp: number;
    };
}

export interface IServerConfigMessage extends IServerMessage {
    type: ServerMessageType.config;
    bet: IBetConfig;
    autoplay: IAutoplayConfig;
    caclulator: ICalculatorConfig;
    serverConfig: IServerConfig;
}

export interface IServerHistoryRoundMultipliers extends IServerMessage {
    type: ServerMessageType.mphistory;
    multipliers: THistoryRoundMultipliersEntry[];
}

export interface IServerRoundResultRes extends IServerMessage {
    type: ServerMessageType.roundResult;
    data: IRoundSource;
}
export interface IServerUserParticipationsRes extends IServerMessage {
    type: ServerMessageType.participations;
    data: IParticipation[];
}
export interface IServerJSONRes<T> extends IServerMessage {
    type: ServerMessageType.json;
    data: T;
}

export interface IPongRes extends IServerMessage {
    type: ServerMessageType.pong;
    date: number;
    socketId?: string;
    [x: string]: unknown;
}
