import { ReactNode, useState } from 'react';
import { BasicSetting, Input, Option, Settings } from '../utils/Settings';
import Checkbox from './other/Checkbox';
import { useEventify } from './utils';
type PickByType<T, Value> = {
    [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};
type PickPropertyTypes<T, V> = {
    [K in keyof T as T[K] extends V ? K : never]: T[K];
};

export function UiOption<
    SettingType extends Option<boolean>,
    B extends { [x: string]: BasicSetting | SettingType },
    T extends Settings<B>,
    E extends Extract<keyof PickPropertyTypes<T['raw'], SettingType>, string>
>({ target, name, icon }: { target: Settings<B>; name: E; icon: ReactNode }) {
    const [value, updateValue] = useState(target.raw[name].value);
    // TODO doublecheck this one
    // @ts-ignore
    useEventify((e) => e.listenTo(target, name, () => updateValue(target.raw[name].value))(), [name]);
    return (
        <UiOptionMarkup
            // TODO doublecheck this one
            // @ts-ignore
            name={target.raw[name].name || name}
            value={value}
            onValue={(value) => {
                updateValue(value);
                target.proxy[name] = value;
            }}
            icon={icon}
        />
    );
}

interface OptionChildProps<T> {
    name: string;
    value: T;
    onValue: (arg: T) => void;
    icon: ReactNode;
}
function UiOptionMarkup({ name, value, onValue, icon }: OptionChildProps<boolean>) {
    return (
        <label className="menu-row">
            <div className="flex flex-row items-center">
                <div className="w-4 mx-1 mr-2 fill-white">{icon}</div>
                <div className="">{name}</div>
            </div>
            <div className="">
                <Checkbox id={name} checked={value} onChange={onValue}></Checkbox>
            </div>
        </label>
    );
}

export function UiInput<
    SettingType extends Input<boolean>,
    B extends { [x: string]: BasicSetting | SettingType },
    T extends Settings<B>,
    E extends Extract<keyof PickPropertyTypes<T['raw'], SettingType>, string>
>({ target, name, icon }: { target: Settings<B>; name: E; icon: ReactNode }) {
    const [value, updateValue] = useState(target.raw[name].value);
    // TODO doublecheck this one
    // @ts-ignore
    useEventify((e) => e.listenTo(target, name, () => updateValue(target.raw[name].value))(), [name]);
    return (
        <UiInputMarkup
            // TODO doublecheck this one
            // @ts-ignore
            name={target.raw[name].name || name}
            value={value}
            onValue={(value) => {
                updateValue(value);
                target.proxy[name] = value;
            }}
            icon={icon}
        />
    );
}

function UiInputMarkup({ name, value, onValue, icon }: OptionChildProps<string>) {
    return (
        <div className="menu-row">
            <div className="flex flex-row items-center">
                <div className="w-4 mx-1 mr-2 fill-white">{icon}</div>
                <div className="">{name}</div>
            </div>
            <div className="">
                <input value={value} onInput={(v) => onValue(v.currentTarget.value)} />
            </div>
        </div>
    );
}
