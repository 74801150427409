import { useContext, useRef, useState } from 'react';
import { App } from '../App';
import { ComponentListen } from '../Components/ComponentListen';
import { Footer } from '../Components/Footer/Footer';
import FunMode from '../Components/FunMode';
import Header from '../Components/Header/Header';
import MultipliersHistory from '../Components/MultipliersHistory';
import { Notifications } from '../Components/Notifications/NotificationsPanel';
import { appContext } from '../Components/contexts';
import { useEventify } from '../Components/utils';

import { ServerMessageType } from '../../../Shared/MessageTypes';
import { RoundStateEvent } from '../../../Shared/Types';
import BetPanelsWrapper from '../Components/BetPanelsWrapper/BetPanelsWrapper';
import Betlog from '../Components/Betlog/Betlog';
import ComponentVisualisation from '../Components/ComponentVisualisation';
import { config } from '../config';
import './Layout.scss';

const colors = Object.entries(config.multiplierColors)
    .map((v) => v.map(Number))
    .sort((a, b) => a[0] - b[0]) as Array<[number, number]>;

const GameLayout = () => {
    const app = useContext(appContext);
    const [isConnected, setIsConnected] = useState(false);
    const [hasDisconnected, setHasDisconnected] = useState(false);
    const [firstRound, setFirstRound] = useState(true);
    const connectedOnceRef = useRef(false);
    const [isVisualisationReady, setIsVisualisationReady] = useState(false);

    useEventify((eventify) => {
        eventify.listenTo(app.network, 'connected', () => {
            setIsConnected(true);
            connectedOnceRef.current = true;
        });
        eventify.listenTo(app.network, 'disconnected', () => {
            setIsConnected(false);
            setHasDisconnected(true);
        });

        eventify.listenTo(app.network, ServerMessageType.serverState, (data) => {
            if (firstRound && data.eventName === RoundStateEvent.EV_ROUND_ENDED) {
                setFirstRound(false);
            }
        });
    }, []);

    if (!isConnected) {
        if (hasDisconnected) {
            return (
                <div className="w-full h-full flex items-center justify-center">
                    <div>{'Trying to reconnect...'}</div>
                </div>
            );
        }
        return (
            <div className="w-full h-full flex items-center justify-center">
                <div>{connectedOnceRef ? 'Reconnecting' : 'Connecting'} ...</div>
            </div>
        );
    }

    if (app.gRPCFailed) {
        return (
            <div className="w-full h-full flex items-center justify-center">
                <div>Error</div>
            </div>
        );
    }

    return (
        <div className="flex flex-col w-full h-full">
            <Header />
            <div className="flex lg:flex-row lg:flex-grow flex-col-reverse w-full p-2 pt-0 gap-2 lg:gap-2 lg:overflow-y-hidden lg:max-h-[calc(100svh-32px] font-roboto">
                <div className="lg:w-[23rem] flex flex-nowrap flex-col place-content-between rounded-xl panel-bg lg:mt-2">
                    <Betlog />

                    <Footer />
                </div>

                <div className="flex flex-col w-full flex-1 overflow-hidden">
                    <MultipliersHistory />
                    <div className="flex relative rounded-xl flex-col flex-grow w-full bg-gray-800 overflow-hidden landscape:max-h-[40svh] landscape:sm:max-h-[calc(210px)] landscape:lg:max-h-[calc(100svh-230px-85px)] sm:h-96 landscape:sm:h- lg:h-6 h-[calc(100svh-85px-350px)] landscape:h-164 ">
                        <FunMode />
                        <ComponentVisualisation onReady={() => setIsVisualisationReady(true)} />
                        {!isVisualisationReady && <div className="absolute inset-0 bg-black z-10"></div>}
                    </div>
                    <BetPanelsWrapper bets={[...app.bets.values()]} />
                </div>
            </div>
        </div>
    );
};
export class Layout extends ComponentListen<{ app: App }> {
    render() {
        // const app = this.props.app;

        return (
            <appContext.Provider value={this.props.app}>
                <Notifications />
                <GameLayout />
            </appContext.Provider>
        );
    }
}
