import Decimal from 'decimal.js-light';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RoundState } from '../../../../Shared/Types';
import Bet from '../../Bet';
import { money } from '../../utils/utils';
import { appContext } from '../contexts';
import { useEventify } from '../utils';

interface BetButtonProps {
    bet: Bet;
    index: number;
}

const isDev = process.env.NODE_ENV === 'development' || process.env.VERCEL_ENV !== 'production';

export default function BetButtons({ bet, index }: BetButtonProps) {
    const app = useContext(appContext);
    const { t } = useTranslation();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const betValueRef = useRef<HTMLDivElement>(null);
    const [fontSizeClass, setFontSizeClass] = useState('text-3xl');
    const [fontSizeClassSmall, setFontSizeClassSmall] = useState('text-2xl');

    useLayoutEffect(() => {
        const updateFontSize = () => {
            const betValue = betValueRef.current?.textContent || '';
            if (betValue.length > 11) {
                setFontSizeClass('text-lg');
                setFontSizeClassSmall('text-sm');
            } else if (betValue.length > 7) {
                setFontSizeClass('text-xl');
                setFontSizeClassSmall('text-xl');
            } else {
                setFontSizeClass('text-3xl');
                setFontSizeClassSmall('text-2xl');
            }
        };

        updateFontSize();

        const observer = new MutationObserver(updateFontSize);
        if (betValueRef.current) {
            observer.observe(betValueRef.current, { characterData: true, childList: true });
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (isDev) {
            document.addEventListener('keydown', (e) => {
                if (bet.id === 0 && e.code === 'KeyJ') {
                    buttonRef.current?.click();
                }

                if (bet.id === 1 && e.code === 'KeyK') {
                    buttonRef.current?.click();
                }
            });
        }

        return () => {
            if (isDev) {
                document.removeEventListener('keydown', () => {});
            }
        };
    }, []);

    const forceUpdate = ((a: Function) => a(Symbol())).bind(null, useState<symbol>()[1]);
    const cashOutRef = useRef<HTMLDivElement>(null);

    useEventify((eventify) => {
        eventify.listenTo(bet.state, 'change:pending', 'change:awaitingBetResponse', 'change:waitBetOpen', forceUpdate);

        eventify.listenTo(bet.ui, 'change:cancelVisible', 'change:cashOutVisible', forceUpdate);

        eventify.listenTo(bet.state, 'change:value', () => {
            betValueRef.current!.textContent = money(bet.state.value, app.config.bet, true);
        });

        eventify.listenTo(app.realtimeState, 'change:multiplier', (currentMultiplier) => {
            if (bet.ui.cashOutVisible && cashOutRef.current) {
                cashOutRef.current!.textContent = bet.getCashOutValueFormatted();
            }

            if (bet.ui.cashOutVisible) {
                // if auto cash out multiplier is smaller then current one --> disable button
                if (
                    bet.state.autoCashoutEnabled &&
                    bet.state.autoCashOutValue < currentMultiplier &&
                    app.state.roundState === RoundState.ROUND_STARTED
                ) {
                    bet.state.pending = true;
                }

                if (new Decimal(bet.state.value).mul(currentMultiplier).greaterThanOrEqualTo(app.config.bet.maxWin)) {
                    bet.state.pending = true;
                }
            }
        });
    }, []);

    // if (bet.ui.cashOutVisible && bet.state.autoCashoutEnabled) {
    //     // if auto cash out multiplier is smaller then current one --> disable button
    //     if (bet.state.autoCashOutValue < app.realtimeMultiplier && app.state.roundState === RoundState.ROUND_STARTED) {
    //         bet.state.pending = true;
    //     }
    // }

    const opacity = bet.state.pending ? 'opacity-50' : 'nothing';
    const buttonFunctionSize = 'text-3xl uppercase shadow-text';
    return (
        <>
            {bet.ui.cancelVisible ? (
                <>
                    <button
                        id={`bet-cancel-button-${index}`}
                        ref={buttonRef}
                        disabled={bet.state.pending || bet.state.awaitingBetResponse}
                        onClick={bet.cancelBet}
                        onMouseDown={() => app.audio.audioList.click?.play()}
                        className={`flex flex-grow flex-col justify-center items-center rounded-3xl w-full h-full bg-[#a31716] hover-supported:hover:bg-[#c41a1a] custom-inner-border ${opacity} ${String(
                            bet.state.pending
                        )} transition-all duration-200 ease-in-out`}
                    >
                        <div className={`${buttonFunctionSize}`}>{t('betPanel.cancel')}</div>
                    </button>
                    <div
                        className={
                            'text-xs p-0.5 font-semibold text-center w-full ' +
                            (!bet.state.waitBetOpen ? 'hidden' : 'visible')
                        }
                    >
                        {t('betPanel.waitingForNextRound')}
                    </div>
                </>
            ) : bet.ui.cashOutVisible ? (
                <button
                    id={`bet-cash-out-button-${index}`}
                    ref={buttonRef}
                    disabled={bet.state.pending || bet.state.awaitingBetResponse}
                    onClick={bet.cashOut}
                    onMouseDown={() => app.audio.audioList.cash_out_click?.play()}
                    className={`flex flex-grow flex-col justify-center items-center rounded-3xl w-full h-full bg-[#a37c16] hover-supported:hover:bg-[#c48f1b] custom-inner-border ${opacity}
                    transition-all duration-200 ease-in-out`}
                >
                    <div className={`${buttonFunctionSize}`}>{t('betPanel.cashOut')}</div>
                    <div className="flex flex-row gap-x-2 shadow-text text-2xl md:text-3xl items-center text-center h-fit">
                        <div className={'flex'} ref={cashOutRef}>
                            xxx
                        </div>
                        <div className="flex h-full items-end text-end justify-end text-xl">{app.config.bet.code}</div>
                    </div>
                </button>
            ) : (
                <button
                    id={`bet-place-button-${index}`}
                    ref={buttonRef}
                    disabled={bet.state.pending || bet.state.awaitingBetResponse}
                    onClick={bet.setBet.bind(bet)}
                    onMouseDown={() => app.audio.audioList.bet_click?.play()}
                    className={`flex flex-grow flex-col justify-center items-center rounded-3xl w-full h-full bg-green-600 hover-supported:hover:bg-green-500 custom-inner-border ${opacity}
                    transition-all duration-200 ease-in-out`}
                >
                    <div className={`${buttonFunctionSize}`}>{t('betPanel.bet')}</div>
                    <div
                        className={`flex flex-row gap-x-2 shadow-text ${fontSizeClass} md:${fontSizeClass} items-center text-center h-fit`}
                    >
                        <div className={'flex'} ref={betValueRef}>
                            {money(bet.state.value, app.config.bet, true)}
                        </div>
                        <div className="flex h-full items-end text-end justify-end text-xl">{app.config.bet.code}</div>
                    </div>
                </button>
            )}
        </>
    );
}
