import Decimal from 'decimal.js-light';
import { config } from '../../config';
import { hexToRgba } from '../../utils/colorUtils';
import { interpolateHexColorsWithStrength, valueToScaleClamped } from '../../utils/utils';

export function MultiplierBadge({
    multiplier,
    opacity = 255,
    onClick,
    style = {},
    colors = Object.entries(config.multiplierColors).map(([key, value]) => [Number(key), value])
}: Readonly<{
    multiplier: number;
    opacity?: number;
    onClick?: () => void;
    style?: React.CSSProperties;
    colors?: Array<[number, number]>;
}>) {
    const roundedMultiplier = new Decimal(multiplier).toDecimalPlaces(2, Decimal.ROUND_FLOOR).toNumber();

    return (
        <div
            className="rounded-2xl whitespace-nowrap text-xs font-semibold px-[6px] py-[2px] bg-black bg-opacity-50 self-center opacity-75 hover:opacity-100 transition-opacity duration-300 ease-in-out "
            style={{
                color: hexToRgba(
                    interpolateHexColorsWithStrength(colors, valueToScaleClamped(roundedMultiplier, 1, 1000)),
                    opacity
                ),
                ...style
            }}
            onClick={onClick}
        >
            {roundedMultiplier?.toFixed(2)}x
        </div>
    );
}
