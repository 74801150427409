import useSWR, { mutate } from 'swr';
import { PeriodArgument } from '../MessageTypes';
import { fetcher, fetcherPlainText, swrFetcher } from './fetcher';

export interface GetPlayersOptions {
    OperatorId?: string;
    UserName?: string;
    Id?: string;
    PageNumber?: number;
    PageSize?: number;
}

export interface GetOperatorsOptions {
    Name?: string;
    Id?: string;
    PageNumber?: number;
    PageSize?: number;
}

export interface GetGameRoundsOptions {
    Id?: string;
    PageNumber?: number;
    PageSize?: number;
}

export interface GetPlayerDetailOptions {
    Token: string;
    PageNumber?: number;
    PageSize?: number;
}

export interface GetPlayerSessionsOptions {
    Id?: string;
    PlayerId?: string;
    Token?: string;
    PageNumber?: number;
    PageSize?: number;
}

export interface GetTransactionsOptions {
    Id?: string;
    SessionId?: string;
    GameRoundId?: string;
    PageNumber?: number;
    PageSize?: number;
}

export interface CreateSessionPayloadOptions {
    player: string;
    balance: number;
}

export const getPlayers = ({ OperatorId, UserName, Id, PageNumber, PageSize }: GetPlayersOptions) => {
    const params = new URLSearchParams();
    if (OperatorId) params.append('OperatorId', OperatorId);
    if (UserName) params.append('UserName', UserName);
    if (Id) params.append('Id', Id);
    if (PageNumber) params.append('PageNumber', PageNumber.toString());
    if (PageSize) params.append('PageSize', PageSize.toString());

    const queryString = params.toString();
    const url = `/Operator/players${queryString ? `?${queryString}` : ''}`;

    return fetcher(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
};

export const getOperators = ({ Name, Id, PageNumber, PageSize }: GetOperatorsOptions) => {
    const params = new URLSearchParams();
    if (Name) params.append('Name', Name);
    if (Id) params.append('Id', Id);
    if (PageNumber) params.append('PageNumber', PageNumber.toString());
    if (PageSize) params.append('PageSize', PageSize.toString());

    const queryString = params.toString();
    const url = `/Operator/operators${queryString ? `?${queryString}` : ''}`;

    return fetcher(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
};

export const getGameRounds = ({ Id, PageNumber, PageSize }: GetGameRoundsOptions) => {
    const params = new URLSearchParams();
    if (Id) params.append('Id', Id);
    if (PageNumber) params.append('PageNumber', PageNumber.toString());
    if (PageSize) params.append('PageSize', PageSize.toString());

    const queryString = params.toString();
    const url = `/Operator/game-rounds${queryString ? `?${queryString}` : ''}`;

    return fetcher(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
};

export const getPlayerDetail = ({ Token, PageNumber, PageSize }: GetPlayerDetailOptions) => {
    const params = new URLSearchParams();
    if (Token) params.append('Token', Token);
    if (PageNumber) params.append('PageNumber', PageNumber.toString());
    if (PageSize) params.append('PageSize', PageSize.toString());

    const queryString = params.toString();
    const url = `/Operator/player-detail${queryString ? `?${queryString}` : ''}`;

    return fetcher(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
};

export const createSession = ({ player, balance }: CreateSessionPayloadOptions) => {
    return fetcherPlainText(`/Operator/create-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ player, balance, gameCode: 'Crash' })
    });
};

// Return game url with TOKEN
export const createDemoSession = () => {
    return fetcherPlainText(
        `/Operator/create-demo-session`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                language: 'EN',
                gameCode: 'Crash'
            })
        },
        process.env.PLATFORM_API_URL
    );
};

export const fetchBetHistory = async (token: string, pageNumber?: number) => {
    try {
        return await fetcher(
            `/Game/player-transactions?Token=${token}${pageNumber ? `&PageNumber=${pageNumber}` : ''}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': '816f2ba8-b53a-4d6a-8af8-767fe30301a7'
                }
            }
        );
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const fetchPlayerData = (token: string) => {
    return fetcherPlainText(
        `/api/Game/init?Token=${token}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': '816f2ba8-b53a-4d6a-8af8-767fe30301a7'
            }
        },
        process.env.GAMEROUND_API_URL
    );
};

export const getPlayerSessions = ({ Id, PlayerId, Token, PageNumber, PageSize }: GetPlayerSessionsOptions) => {
    const params = new URLSearchParams();
    if (Id) params.append('Id', Id);
    if (PlayerId) params.append('PlayerId', PlayerId);
    if (Token) params.append('Token', Token);
    if (PageNumber) params.append('PageNumber', PageNumber.toString());
    if (PageSize) params.append('PageSize', PageSize.toString());

    const queryString = params.toString();
    const url = `/Operator/sessions${queryString ? `?${queryString}` : ''}`;

    return fetcher(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
};

export const getTransactions = ({ Id, SessionId, GameRoundId, PageNumber, PageSize }: GetTransactionsOptions) => {
    const params = new URLSearchParams();
    if (Id) params.append('Id', Id);
    if (SessionId) params.append('SessionId', SessionId);
    if (GameRoundId) params.append('GameRoundId', GameRoundId);
    if (PageNumber) params.append('PageNumber', PageNumber.toString());
    if (PageSize) params.append('PageSize', PageSize.toString());

    const queryString = params.toString();
    const url = `/Operator/transactions${queryString ? `?${queryString}` : ''}`;

    return fetcher(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
};

export const getDashboard = () => {
    return fetcher(`/Operator/dashboard`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
};

export const getGameChat = () => {
    return fetcher(`/Game/chat`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
};

export const getGameHistory = () => {
    type GameHistoryItem = {
        id: string;
        start: string;
        end: string;
        multiplier: number;
        active: boolean;
        created: string;
    };

    // TODO fetch more games to fill the whole history
    const key = '/Game/history';
    const res = useSWR(key, swrFetcher, {
        refreshInterval: 0,
        revalidateOnFocus: false
    });

    const updateCache = (data: GameHistoryItem[]) => mutate(key, data, { revalidate: true });

    return {
        ...res,
        games: (res.data?.items || []) as GameHistoryItem[],
        updateCache
    };
};

const LEADERBOARD_PERIOD = {
    day: 1,
    month: 2,
    year: 3,
    all: 4
} as const;

export const getWinsLeaderboard = (period: PeriodArgument = PeriodArgument.day) => {
    const type = LEADERBOARD_PERIOD[period];
    const endpoint = `/Game/win-leaderboard?LeaderboardType=${type}`;
    const res = useSWR(endpoint, swrFetcher, {
        refreshInterval: 0
    });

    return {
        ...res,
        bets: (res?.data?.items || []) as Array<{
            bet: number;
            created: string;
            multiplier: number;
            player: string;
            winFinalAmount: number;
            currency: string;
            finalAmount: number;
            id: string;
            gameRoundId: string;
            playerId: string;
            username: string;
        }>
    };
};

export const getMultipliersLeaderboard = (period: PeriodArgument = PeriodArgument.day) => {
    const type = LEADERBOARD_PERIOD[period];
    const endpoint = `/Game/multiplier-leaderboard?LeaderboardType=${type}`;
    const res = useSWR(endpoint, swrFetcher, {
        refreshInterval: 0
    });

    return {
        ...res,
        bets: (res?.data?.items || []) as Array<{
            bet: number;
            created: string;
            multiplier: number;
            player: string;
            winFinalAmount: number;
            currency: string;
            finalAmount: number;
            id: string;
            gameRoundId: string;
            playerId: string;
            username: string;
        }>
    };
};

export const getPreviousHand = () => {
    const endpoint = `/Game/last-round`;
    const res = useSWR(endpoint, swrFetcher, {
        refreshInterval: 0
    });

    return res;
};

export const getPlayerTransactions = () => {
    const endpoint = '/Game/player-transactions';
    const res = useSWR(endpoint, swrFetcher, {
        refreshInterval: 0
    });

    return {
        ...res,
        transactions: res.data?.items
    };
};

// POST api/Game/cancel
export const postGameCancel = (betId: string) => {
    const requestData = {
        betId
    };

    return fetcher(`/Game/cancel`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-Api-Key': '816f2ba8-b53a-4d6a-8af8-767fe30301a7' },
        body: JSON.stringify(requestData)
    });
};

// GET /api/Game/round=info

export const getRoundInfo = async (GameRoundId: string) => {
    try {
        return await fetcher(`/Game/round-info?GameRoundId=${GameRoundId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
    } catch (e) {
        console.error(e);
        return null;
    }
};

// avatar
export const sendAvatarChange = async (avatarId: number) => {
    const parseUrl = new URL(window.location.href);
    const token = parseUrl.searchParams.get('token');

    try {
        return await fetcher(`/Game/avatar`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                token,
                avatarId
            })
        });
    } catch (e) {
        console.error(e);
        return null;
    }
};
