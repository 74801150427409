import moment, { Moment } from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'react-scrollbars-custom';
import { IBetInfo } from '../../../../Shared/Types';
import { MessageIcon, ShieldIcon } from '../../../assets/icons';
import SuperMap from '../../utils/SuperMap';
import { appContext } from '../contexts';
import { MultiplierBadge } from '../other/MultiplierBadge';
import { useEventify } from '../utils';
import { ShieldSecurityIcon } from '../../../assets/icons/ShieldSecurityIcon';
import { ScrollState } from 'react-scrollbars-custom/dist/types/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ServerBetType = any;

export const scrollFetchThreshold = 100;

export type MyBetType = {
    id: string;
    userId: string;
    roundId: string;
    date: Moment;
    amount: number;
    multiplier: number;
    bet: number;
    win: number;
    username: string;
    currency: string;
};

export const mapServerBetToIBetInfo = (bet: ServerBetType): MyBetType => ({
    id: bet.id,
    userId: bet.playerId || '',
    roundId: bet.gameRoundId,
    date: moment(bet.created),
    amount: bet.finalAmount,

    // below defaults are needed since server returns null instead of 0
    multiplier: bet.multiplier || 0,
    bet: bet.finalAmount || 0,
    win: bet.winFinalAmount || 0,
    username: bet.playerId || '',
    currency: bet.currency || ''
});

const sortBets = (a: MyBetType, b: MyBetType) => moment(b.date).diff(moment(a.date));

export function MyBetsContainer() {
    const betItemHeight = 26;

    const { t } = useTranslation();
    const app = useContext(appContext);

    const [myBets, setMyBets] = useState<ServerBetType[]>([...app.myBets.values()].sort(sortBets));
    const [totalHeight, setTotalHeight] = useState(app.myBetsTotal * betItemHeight);

    useEventify((eventify) => {
        eventify.listenTo(app.myBets, SuperMap.Events.all, (data) => {
            setMyBets([...app.myBets.values()].sort(sortBets));
            setTotalHeight(app.myBetsTotal * betItemHeight);
        });
    }, []);

    const handleScroll = (scrollValues: any) => {
        if (scrollValues.scrollTop + scrollValues.clientHeight + scrollFetchThreshold >= scrollValues.scrollHeight)
            app.fetchMyBetHistoryNextPage();
    };

    return (
        <>
            <div className="flex flex-row flex-grow-1 px-2.5 text-[#a8a29e]">
                <div className="w-1/5">{t('sidebar.date')}</div>
                <div className="w-2/5 flex-1 text-center">{t('sidebar.bet')}</div>
                <div className="w-2/5 flex-1 text-right pr-11">{t('sidebar.cashOut')}</div>
            </div>
            <Scrollbar
                onScroll={handleScroll}
                className="flex flex-row h-full px-1.5 custom-scrollbar-container"
                thumbYProps={{
                    renderer: (props) => {
                        const { elementRef, style, ...restProps } = props;
                        return (
                            <div
                                {...restProps}
                                ref={elementRef}
                                style={{
                                    ...style,
                                    backgroundColor: '#ffda00'
                                }}
                            />
                        );
                    }
                }}
            >
                <div className="flex flex-col w-full gap-1.5 text-sm" style={{ paddingRight: '4px' }}>
                    {myBets.map((betInfo, index) => {
                        return <BetItem key={betInfo.id} data={betInfo} index={index} />;
                    })}
                </div>
            </Scrollbar>
        </>
    );
}

function BetItem({ data, index }: { data: IBetInfo, index: number }) {
    const modalOpened = useState(false);
    function toggleState() {
        modalOpened[1](!modalOpened[0]);
    }

    return (
        <div
            id={`my-bet-entry-${index}`}
            className="flex flex-row border border-transparent rounded-md panel-bg-alt p-0.5 text-[#a8a29e]"
        >
            <div id={`my-bet-entry-date-${index}`} className="flex items-center gap-1 w-1/5 text-[#fefce8]">
                <div className="flex flex-shrink">{moment(data.date).format('HH:mm')}</div>
            </div>
            <div className="flex items-center w-2/5 gap-1">
                <div id={`my-bet-entry-amount-${index}`} className="flex flex-row justify-end w-2/3">
                    <span>{data.currency} </span>
                    <span className="text-[#fefce8] ml-1">{Number(data.bet || 0).toFixed(2)}</span>
                </div>
                <div className="flex justify-start w-1/3">
                    <div id={`my-bet-entry-multiplier-${index}`} className={data.multiplier == 0 ? ' hidden' : ''}>
                        <MultiplierBadge multiplier={data.multiplier} />
                    </div>
                </div>
            </div>
            <div className="flex flex-row w-2/5 items-center justify-end gap-6 pr-1">
                <div id={`my-bet-entry-cashout-${index}`}>
                    <span>{data.currency}</span>
                    <span className="text-[#fefce8] ml-1">{Number(data.win || 0).toFixed(2)}</span>
                </div>
                <div
                    id={`my-bet-entry-security-${index}`}
                    className="flex gap-1"
                    data-round-id={data.roundId}
                    onClick={toggleState}
                >
                    <ShieldSecurityIcon />
                </div>
            </div>
        </div>
    );
}
