/* eslint-disable @typescript-eslint/no-var-requires */
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { decode as decodeMsgPack, encode as encodeMsgPack } from '@msgpack/msgpack';
import { Debugger } from '../../Shared/Debugger';
import { deferrify, Eventify, EventifyMixin } from '../../Shared/Eventify';
import { RoundState2Message } from '../../Shared/EventRoundStates';
import {
    ClientMessageType,
    IClientAuthReq,
    IClientMessage,
    IClientRoundInfoReq,
    IServerBetCancelMessage,
    IServerBetMessage,
    IServerBetProceededMessage,
    IServerMessage,
    Message,
    MessageTypes
} from '../../Shared/MessageTypes';
import { RoundStateEvent } from '../../Shared/Types';
import { ClientBuildEnvironment } from './Types';
import { logInfo } from './utils/utils';

declare const __ENVIRONMENT__: ClientBuildEnvironment;

const decode = process.env.USE_JSON_SIGNALR === 'true' ? JSON.parse : decodeMsgPack;
const encode = process.env.USE_JSON_SIGNALR === 'true' ? JSON.stringify : encodeMsgPack;

const enum ClientMethodName {
    Bet = 'Bet',
    BetProceeded = 'BetProceeded',
    BetOpened = 'BetOpened',
    BetClose = 'BetClosed',
    CashOut = 'CashOut',
    CashOutProceeded = 'CashOutProceeded',
    PingCaller = 'PingCaller',
    GameRound = 'GameRound',
    StartGameRound = 'StartGameRound',
    EndGameRound = 'EndGameRound',
    BetCancel = 'CancelBet',
    BetCancelProceeded = 'CancelBetProceeded',
    Error = 'Error',
    Close = 'Close'
}

const enum ServerMethodName {
    Bet = 'Bet',
    BetCancel = 'CancelBet',
    PingCaller = 'PingCaller',
    CashOut = 'CashOut'
}

export const enum wsErrorStatus {
    ERROR_BET_NOT_FOUND = 'ERROR_BET_NOT_FOUND',
    ERROR_GAMEROUND_ALREADY_STARTED = 'ERROR_GAMEROUND_ALREADY_STARTED',
    ERROR_BET_HAS_NOT_AUTOCASHOUT = 'ERROR_BET_HAS_NOT_AUTOCASHOUT',
    ERROR_BET_AUTOCASHOUT_MUST_BE_POSITIVE = 'ERROR_BET_AUTOCASHOUT_MUST_BE_POSITIVE',
    ERROR_BET_ALREADY_HAS_CASHOUT = 'ERROR_BET_ALREADY_HAS_CASHOUT',
    ERROR_BET_ALREADY_HAS_REFUND = 'ERROR_BET_ALREADY_HAS_REFUND',
    ERROR_MULTIPLIER_HIGHER_THAN_CALCULATED = 'ERROR_MULTIPLIER_HIGHER_THAN_CALCULATED',
    ERROR_CUSTOMER_NOT_EXISTS = 'ERROR_CUSTOMER_NOT_EXISTS',
    ERROR_CUSTOMER_IS_BLOCKED = 'ERROR_CUSTOMER_IS_BLOCKED',
    ERROR_CURRENCY_NOT_ENABLED_FOR_OPERATOR = 'ERROR_CURRENCY_NOT_ENABLED_FOR_OPERATOR',
    ERROR_CURRENCY_IS_BLOCKED_FOR_OPERATOR = 'ERROR_CURRENCY_IS_BLOCKED_FOR_OPERATOR',
    ERROR_BET_HIGHER_THEN_MAX = 'ERROR_BET_HIGHER_THEN_MAX',
    ERROR_CURRENCY_DIFFERENT_THAN_OPERATOR_CURRENCY = 'ERROR_CURRENCY_DIFFERENT_THAN_OPERATOR_CURRENCY',
    ERROR_GAMEROUND_NOT_EXISTS = 'ERROR_GAMEROUND_NOT_EXISTS',
    ERROR_WIN_HIGHER_THEN_MAX = 'ERROR_WIN_HIGHER_THEN_MAX',
    ERROR_START_GAME_NOT_EXISTS = 'ERROR_START_GAME_NOT_EXISTS',
    ERROR_CURRENCY_NOT_EXISTS = 'ERROR_CURRENCY_NOT_EXISTS',
    ERROR_UPDATE_SESSION_NEGATIVE_BALANCE = 'ERROR_UPDATE_SESSION_NEGATIVE_BALANCE',
    ERROR_CREATE_DEMO_NOT_EXISTS = 'ERROR_CREATE_DEMO_NOT_EXISTS',
    ERROR_IP_BLOCKED = 'ERROR_IP_BLOCKED',
    ERROR_COUNTRY_BLOCKED = 'ERROR_COUNTRY_BLOCKED',
    ERROR_PLAYER_LOCKED = 'ERROR_PLAYER_LOCKED',
    ERROR_PLAYER_ALREADY_PLACE_TWO_BETS = 'ERROR_PLAYER_ALREADY_PLACE_TWO_BETS'
}

export default class Network extends EventifyMixin(Debugger) {
    // socket!: WebSocket | FakeWebSocket;
    mainConnection!: HubConnection; // Change type to HubConnection
    secondaryConnection!: HubConnection;
    comebackTimeout = 500;
    responseCallbacks = new Eventify();
    username: string | null;
    currency: string | null;

    constructor() {
        super();
        this.setPrefix('[[36mNetwork[39m]:');
        this.isLogging = !false;
        this.username = null;
        this.currency = null;
    }

    connectHubSocket(hubUrl: string, token: string) {
        let connection: HubConnection;
        if (process.env.USE_JSON_SIGNALR === 'true') {
            connection = new HubConnectionBuilder()
                .configureLogging(6)
                .withUrl(hubUrl, {
                    withCredentials: true,
                    accessTokenFactory: () => token as string
                })
                .build();
        } else {
            connection = new HubConnectionBuilder()
                .configureLogging(6)
                .withUrl(hubUrl, {
                    withCredentials: true,
                    accessTokenFactory: () => token as string
                })
                .withHubProtocol(new MessagePackHubProtocol())
                .build();
        }
        connection.keepAliveIntervalInMilliseconds = 5000;
        connection.serverTimeoutInMilliseconds = 60000;

        console.log('connection', connection);

        return connection;
    }

    connect(username: string | null, currency: string | null) {
        // this.log('Connecting...');
        this.username = username;

        const mainUrl = `${process.env.GAMEROUND_API_URL}/game?username=${username}&currency=${currency}`;
        const secondaryUrl = `${process.env.GAMEROUND_API_URL}/game-proceed?username=${username}&currency=${currency}`;
        const parseUrl = new URL(window.location.href);
        const token = parseUrl.searchParams.get('token');

        this.mainConnection = this.connectHubSocket(mainUrl, token as string);
        this.secondaryConnection = this.connectHubSocket(secondaryUrl, token as string);

        this.mainConnection
            .start()
            .then(() => {
                // this.log('Connected');
                this.onopen();
            })
            .catch((error) => {
                // this.log('Connection failed', error);
            });

        this.secondaryConnection.onclose((error) => {
            this.onclose(error);
        });

        this.secondaryConnection.start().catch((error) => {
            // this.log('Connection failed', error);
        });

        this.mainConnection.onreconnected(() => {
            this.onopen();
        });

        this.mainConnection.onreconnecting(() => {
            logInfo('reconnecting');
            this.rejectAllCallbacks();
            this.reconnect();
        });

        this.mainConnection.onclose((error) => {
            this.onclose(error);
        });

        this.connectToUserEvents(this.mainConnection);
        this.connectToUserEvents(this.secondaryConnection);
    }

    connectToUserEvents(connection: HubConnection) {
        connection.on(ClientMethodName.Close, (binary) => {
            void connection.stop();
        });

        connection.on(ClientMethodName.PingCaller, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: 'PingResponse',
                        serverTimestamp: data.ServerTimestamp,
                        clientTimestamp: data.ClientTimestamp
                    }
                })
            } as MessageEvent<string>);
        });

        connection.on(ClientMethodName.Error, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);
            logInfo('WS Error:', data);
            this.emit('error', data);
        });

        connection.on(ClientMethodName.CashOut, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            logInfo('>> cash out response from server', data);

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        // TODO fix this one
                        type: 'cashOutEvent',
                        eventName: data.EventName,
                        eventData: {
                            betId: data.EventData.BetId,
                            username: data.EventData.Username,
                            win: data.EventData.Win,
                            balance: data.EventData.Balance,
                            timeElapsed: data.EventData.TimeElapsed,
                            multiplier: data.EventData.Multiplier,
                            timestamp: data.EventData.Timestamp
                        }
                    }
                })
            } as MessageEvent<string>);
        });

        // cashOut proceeded - add data to leader board - broadcast to all clients
        connection.on(ClientMethodName.CashOutProceeded, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            logInfo('>> cash out proceeded response', data);

            for (const item of data) {
                const parsedData = {
                    userId: item.EventData.Token,
                    betId: item.EventData.BetId,
                    username: item.EventData.Username,
                    win: item.EventData.CashOut,
                    timeElapsed: item.EventData.TimeElapsed,
                    multiplier: item.EventData.Multiplier,
                    amount: item.EventData.Amount,
                    avatarId: item.EventData.AvatarId,
                    currency: item.EventData.Currency
                };

                this.onmessage({
                    data: JSON.stringify({
                        m: MessageTypes.event,
                        data: {
                            type: item.Type,
                            eventName: item.EventName,
                            eventData: parsedData
                        }
                    })
                } as MessageEvent<string>);
            }
        });

        connection.on(ClientMethodName.StartGameRound, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            const parsedData: RoundState2Message[RoundStateEvent.EV_ROUND_STARTED] = {
                serverState: data.EventData.ServerState,
                roundState: data.EventData.RoundState,
                multiplier: data.EventData.Multiplier,
                roundStartTime: data.EventData.RoundStartTime
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        connection.on(ClientMethodName.GameRound, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            const parsedData: RoundState2Message[RoundStateEvent.EV_MULTIPLIER] = {
                multiplier: data.EventData.Multiplier,
                elapsedTime: data.EventData.ElapsedTime
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        connection.on(ClientMethodName.EndGameRound, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            const parsedData: RoundState2Message[RoundStateEvent.EV_ROUND_ENDED] = {
                roundState: data.EventData.RoundState,
                serverState: data.EventData.ServerState,
                lastProgress: data.EventData.LastProgress,
                multiplier: data.EventData.Multiplier,
                roundEndTime: data.EventData.RoundEndTime,
                roundId: data.EventData.RoundId,
                elapsedTime: data.EventData.RoundTime
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        connection.on(ClientMethodName.BetClose, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            const parsedData: RoundState2Message[RoundStateEvent.EV_BET_CLOSED] = {
                serverState: data.EventData.ServerState,
                roundState: data.EventData.RoundState
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        connection.on(ClientMethodName.BetOpened, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            const parsedData: RoundState2Message[RoundStateEvent.EV_BET_OPENED] = {
                serverState: data.EventData.ServerState,
                roundState: data.EventData.RoundState,
                multiplier: data.EventData.Multiplier,
                betTimeout: data.EventData.BetTimeout,
                betOpenTime: data.EventData.BetOpenTime,
                betEndTime: data.EventData.BetEndTime
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        // TODO double check this one
                        type: 'serverState',
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        //bet - personal bet - only for the user
        connection.on(ClientMethodName.Bet, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            logInfo('>> bet response', data);

            const parsedData: IServerBetMessage['eventData'] = {
                balance: data.EventData.Balance,
                betId: data.EventData.BetId,
                roundId: data.EventData.GameRound,
                index: data.EventData.Index,
                timestamp: data.EventData.Timestamp
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        // bet proceeded - add data to leader board - broadcast to all clients
        connection.on(ClientMethodName.BetProceeded, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            logInfo('>> bet proceeded response', data);

            for (const item of data) {
                const parsedData = {
                    username: item.EventData.Username,
                    userId: item.EventData.Token,
                    amount: item.EventData.Amount,
                    betId: item.EventData.BetId,
                    multiplier: 0,
                    win: 0,
                    avatarId: item.EventData.AvatarId,
                    currency: item.EventData.Currency
                };

                this.onmessage({
                    data: JSON.stringify({
                        m: MessageTypes.event,
                        data: {
                            type: item.Type,
                            eventName: item.EventName,
                            eventData: parsedData
                        }
                    })
                } as MessageEvent<string>);
            }
        });

        connection.on(ClientMethodName.BetCancel, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { EventData, Type, EventName }: any = decode(binary);

            logInfo('>> bet cancel response', decode(binary));

            const parsedData: IServerBetCancelMessage['eventData'] = {
                balance: EventData.Balance,
                roundId: EventData.RoundId,
                index: EventData.Index,
                betId: EventData.BetId,
                token: EventData.Token,
                timestamp: EventData.Timestamp
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: Type,
                        eventName: EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        connection.on(ClientMethodName.BetCancelProceeded, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { EventData, Type, EventName }: any = decode(binary);

            logInfo('>> bet cancel proceeded response', decode(binary));
            const parsedData = {
                betId: EventData.BetId
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: Type,
                        eventName: EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });
    }

    close() {
        // if (this.socket) {
        //     this.socket.onopen = null;
        //     this.socket.onmessage = null;
        //     this.socket.onerror = null;
        //     this.socket.onclose = null;
        //     if (this.isSocketOpen()) {
        //         this.log('Disconnected');
        //         this.socket.close();
        //         this.rejectAllCallbacks();
        //     }
        // }
        // this.socket = undefined as unknown as WebSocket;
        if (this.mainConnection) {
            this.mainConnection.stop();
        }
    }
    isSocketOpen() {
        return this.mainConnection.state === 'Connected';
    }

    increaseComeback() {
        this.comebackTimeout = this.comebackTimeout < 40000 ? this.comebackTimeout * 2 : 40000;
    }

    reconnect() {
        if (typeof setTimeout !== 'function') {
            logInfo('setTimeout is not a function');
            return;
        }
        setTimeout(() => {
            this.connect(this.username, this.currency);
        }, this.comebackTimeout);

        this.increaseComeback();
    }

    private onopen() {
        // this.log('Connected');
        this.emit('connected');
        this.comebackTimeout = 500;
    }

    private onmessage(data: MessageEvent<string>) {
        const message = JSON.parse(data.data);

        if (message.data) {
            this.emit(message.data.type, message.data);
        }

        if (message.callback)
            this.responseCallbacks.emit(message.callback, message.data, message.errorMessage !== undefined);

        if (message.errorMessage) {
            this.emit('notification', {
                type: 'error',
                message: message.errorMessage
            });
        }
    }

    private onerror() {}

    private onclose(error: Error | undefined) {
        // this.log('Disconnected');
        logInfo('error', error);
        this.emit('disconnected');
        if (
            error?.message === 'Server timeout elapsed without receiving a message from the server.' ||
            error?.message === 'WebSocket closed with status code: 1006 (Abnormal closure)' ||
            error?.message === 'WebSocket closed with status code: 1006 (no reason given).'
        ) {
            this.reconnect();
            this.emit('resetVisualisation');
        }
        this.rejectAllCallbacks();
    }

    private rejectAllCallbacks() {
        for (const callbackId in this.responseCallbacks.events) {
            this.responseCallbacks.emit(callbackId, null, true);
        }
        this.emit('notification', {
            type: 'error',
            message: 'Server mainConnection closed'
        });
    }

    //never mind
    send(message: IClientMessage, signal?: AbortSignal) {
        //Send socket - do not use (we will use rest api)
        // todo сделать запвисмость от состояния сокета. если он открывается, то поставить промис
        // if (!(this.socket && this.isSocketOpen())) return Promise.reject();
        if (!this.isSocketOpen()) return Promise.reject();
        const dpromise = deferrify<IServerMessage>(signal ? { signal } : undefined);

        const id = Math.random().toString(36).substring(2, 9);

        const body: Message = {
            m: MessageTypes.rpc,
            callback: id,
            data: message
        };

        const listener = (response: IServerMessage, isRejected: boolean) => {
            this.responseCallbacks.removeListener(id, listener);

            if (isRejected) {
                dpromise.reject(response);
            } else {
                dpromise.resolve(response);
            }
        };
        this.responseCallbacks.on(id, listener);

        // this.log('out', body);
        this.mainConnection.send(JSON.stringify(body));

        return dpromise.promise;
    }

    sendPing() {
        try {
            const startTimestamp = Date.now();
            const binary = encode({ ClientTimestamp: startTimestamp });

            const invokePromise = this.mainConnection.invoke(ServerMethodName.PingCaller, binary);

            return { startTimestamp, invokePromise };
        } catch (e) {
            logInfo('error', e);
        }
    }

    sendAuth(data: Omit<IClientAuthReq, 'type'>) {
        const auth: IClientAuthReq = {
            type: ClientMessageType.auth,
            ...data
        };
        return this.send(auth);
    }

    sendCashOut(betId: string) {
        try {
            const data = { BetId: betId };
            logInfo('>> cash out request', data);

            const binary = encode(data);
            return this.mainConnection.invoke(ServerMethodName.CashOut, binary);
        } catch (e) {
            logInfo('error', e);
        }
    }

    sendSetBet({
        amount,
        autoCashOutMultiplier,
        id,
        avatarId
    }: {
        amount: number;
        autoCashOutMultiplier?: number | null;
        id: number;
        avatarId: number | string;
    }) {
        try {
            const data = {
                Amount: amount,
                Index: id, // we need to be able to recognize which button/bet was sent
                WsConnectionId: this.mainConnection.connectionId,
                AvatarId: avatarId,
                ...(autoCashOutMultiplier ? { AutoCashout: autoCashOutMultiplier } : {})
            };

            logInfo('>> bet request', data);

            const binary = encode(data);
            return this.mainConnection.invoke(ServerMethodName.Bet, binary);
        } catch (e) {
            logInfo('error', e);
        }
    }

    sendCancelBet(betId: string) {
        try {
            const data = { BetId: betId, Currency: 'XXX' };
            logInfo('>> bet cancel', data);

            const binary = encode(data);
            return this.mainConnection.invoke(ServerMethodName.BetCancel, binary);
        } catch (e) {
            logInfo('error', e);
        }
    }

    //todo rest api
    getRoundInfo(roundId: string) {
        const data: IClientRoundInfoReq = {
            type: ClientMessageType.roundInfo,
            roundId: roundId
        };

        return {
            id: 'test-id',
            betOpen: 10,
            betClose: 100,
            roundStart: 1000,
            roundEnd: 1000,
            multiplier: 1.3244,
            serverSeed: 'server-seed-test',
            participants: [],
            bets: [],
            hash: 'hash-test',
            hex: 'hex-string-test',
            decimal: 1.0
        };
    }
}

//todo refactor
export interface CashoutResponse {
    token?: string;
    balance?: number;
    transactionId?: string;
}
