import React, { Key, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'react-scrollbars-custom';
import { getPreviousHand } from '../../../../Shared/services/queries';
import { HistoryIcon } from '../../../assets/icons/HistoryIcon';
import { BetBoardEntry } from '../../Objects';
import SuperMap from '../../utils/SuperMap';
import { appContext } from '../contexts';
import Avatar from '../other/Avatar';
import { MultiplierBadge } from '../other/MultiplierBadge';
import Username from '../other/Username';
import { useEventify } from '../utils';

export function AllBets() {
    const [isPreviousOpened, setIsPreviousOpened] = useState(false);
    const { t } = useTranslation();

    const buttonBasicStyles = 'flex items-center rounded-full border text-xs px-2 py-1 gap-2';
    const buttonNormalStyles = 'border-gray-700 panel-bg-alt';
    const buttonActiveStyles = 'border-red-700 panel-bg-red-alt';

    const button = (
        <div>
            <button
                onClick={setIsPreviousOpened.bind(null, !isPreviousOpened)}
                className={`${buttonBasicStyles} ${!isPreviousOpened ? buttonNormalStyles : buttonActiveStyles}`}
            >
                <div className="w-2 mr-0.5">{isPreviousOpened ? '✕' : <HistoryIcon />}</div> {t('sidebar.previousHand')}
            </button>
        </div>
    );
    if (isPreviousOpened) return <PrevRoundBets button={button} />;
    return <CurrRoundBets button={button} />;
}

function CurrRoundBets({ button }: { button?: React.ReactNode }) {
    const app = useContext(appContext);

    const [currentBetBoard, setCurrentBetBoard] = useState<BetBoardEntry[]>([...app.currentBetBoard.values()]);

    useEventify((eventify) => {
        eventify.listenTo(app.currentBetBoard, SuperMap.Events.all, (data) => {
            setCurrentBetBoard([...app.currentBetBoard.values()]);
        });
    }, []);

    return <RoundBetsTable data={currentBetBoard} button={button} multiplier={0} />;
}

export function PrevRoundBets({ button }: { button?: React.ReactNode }) {
    const app = useContext(appContext);
    // const [prevBetBoard, setPrevBetBoard] = useState<IBetTopEntryProps[]>([]);
    const [prevMultiplier, setPrevMultiplier] = useState(0);

    const { data } = getPreviousHand();

    const transformed = useMemo(() => {
        if (!data) return [];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (data.items || []).map((entry: any) => ({
            currency: entry.currency,
            bet: entry.finalAmount,
            multiplier: entry.multiplier,
            win: entry.winFinalAmount,
            time: entry.created,
            username: entry.playerId,
            img: entry.img,
            userId: entry.playerId
        }));
    }, [data]);

    return <RoundBetsTable data={transformed} button={button} multiplier={prevMultiplier} />;
}

function RoundBetsTable({
    data,
    multiplier = 0,
    button
}: {
    data: BetBoardEntry[];
    multiplier: number;
    button?: React.ReactNode;
}) {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex justify-between items-center px-2.5 text-sm text-[#a8a29e]">
                <div className="flex items-center flex-col">
                    <div>{t('sidebar.allBets')}</div>
                    <div className="text-[#fefce8]">{data.length}</div>
                </div>
                {multiplier !== 0 ? (
                    <div>
                        <MultiplierBadge multiplier={multiplier} />
                    </div>
                ) : undefined}

                {button}
            </div>
            <div className="flex flex-row flex-grow-1 px-2.5 text-[#a8a29e]">
                <div className="flex-1">{t('sidebar.user')}</div>
                <div className="flex-1 text-center">{t('sidebar.bet')}</div>
                <div className="flex-1 text-right">{t('sidebar.cashOut')}</div>
            </div>
            <Scrollbar
                className="flex flex-row h-full grow-0 overflow-y-scroll px-1.5 overflow-x-hidden custom-scrollbar-container"
                thumbYProps={{
                    renderer: (props) => {
                        const { elementRef, style, ...restProps } = props;
                        return (
                            <div
                                {...restProps}
                                ref={elementRef}
                                style={{
                                    ...style,
                                    backgroundColor: '#ffda00'
                                }}
                            />
                        );
                    }
                }}
            >
                <div className="flex flex-col w-full gap-1.5 text-sm" style={{ paddingRight: '4px' }}>
                    {data && data.map((entry) => <BetParticipationEntry key={entry.id as Key} data={entry} />)}
                </div>
            </Scrollbar>
        </>
    );
}

function BetParticipationEntry({ data }: { data: BetBoardEntry }) {
    const app = useContext(appContext);
    return (
        <div className="flex flex-row border border-transparent rounded-md panel-bg-alt p-0.5 text-[#fefce8]">
            <div className="flex items-center gap-1 w-1/4  flex-0">
                <Avatar data-avatarid={`${data.img}`} />
                <div className="flex flex-shrink flex-nowrap whitespace-nowrap">
                    <Username name={data.username} data-userid={data.userId} className="truncate max-w-[120px]" />
                </div>
            </div>
            <div className="flex items-center w-2/4 gap-1">
                <div className="flex justify-end w-1/2">
                    <span className="text-[#a8a29e] mr-1">{data.currency}</span>
                    <span>{Number(data.bet || 0).toFixed(2)}</span>
                </div>
                <div className="flex justify-start w-1/2 mr-1">
                    <div className={data.multiplier == 0 ? 'hidden' : ''}>
                        <MultiplierBadge multiplier={data.multiplier} />
                    </div>
                </div>
            </div>
            <div className="flex flex-row w-1/4 items-center justify-end">
                <span className="text-[#a8a29e] mr-1">{data.currency}</span>
                <span>{Number(data.win || 0).toFixed(2)}</span>
            </div>
        </div>
    );
}
