import { t } from 'i18next';
import { Eventify } from '../../../Shared/Eventify';
import { storage } from './storage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class BasicSetting<V = any> {
    exportable = true;
    path?: string;
    name?: string;
    type = 'NONE';
    value: V = null as unknown as V;
    default: V = null as unknown as V;
    constructor({ path = 'DEFAULT', name = '' }: { path?: string; name?: string }) {
        this.path = path;
        this.name = name;
    }
    setter(data: V) {
        this.value = data;
    }
    get export() {
        return this.value;
    }
}

export class Select<T = string | number> extends BasicSetting<T> {
    options: Record<string, T>;
    value: T;
    default: T;
    constructor({
        name,
        options = {},
        value,
        path
    }: {
        name?: string;
        options: Record<string, T>;
        value: T;
        path?: string;
    }) {
        super({ name, path });
        this.type = 'SEL';
        this.options = options;
        this.value = value;
        this.default = value;
        this.setter(value);
    }
    get export() {
        return this.value;
    }
    toJSON() {
        return this.export;
    }
    setter(data: T) {
        let isError = true;
        for (const [, value] of Object.entries(this.options)) {
            if (value === data) {
                isError = false;
            }
        }
        if (isError) {
            console.error('Select: Invalid value ', data, ', fallback to default', this, this.default);
            this.value = this.default;
            return;
        }
        this.value = data;
    }
}
export class Slider<T = number> extends BasicSetting<T> {
    min: number;
    max: number;
    step: number;
    value: T;
    default: T;
    useCss: boolean;
    dim: string;
    unit: string;
    constructor({
        name,
        min,
        max,
        step,
        value,
        useCss = false,
        dim = '',
        unit = '',
        path
    }: {
        name?: string;
        min: number;
        max: number;
        step: number;
        value: T;
        useCss?: boolean;
        dim?: string;
        path?: string;
        unit?: string;
    }) {
        super({ name, path });
        this.type = 'SLD';
        this.min = min;
        this.max = max;
        this.step = step;
        this.value = value;
        this.default = value;
        this.useCss = useCss;
        this.dim = dim;
        this.unit = unit;
        this.setter(value);
    }
    get export() {
        return this.value;
    }
    toJSON() {
        return this.export;
    }
    setter(data: T) {
        if (typeof data !== 'number' || typeof data == 'undefined') data = this.default;
        this.value = data;
    }
}
export class Option<T = boolean> extends BasicSetting<T> {
    value: T;
    default: T;
    constructor({ name, value, path }: { name?: string; value: T; path?: string }) {
        super({ name, path });
        this.type = 'OPT';
        this.value = value;
        this.default = value;
        this.setter(value);
    }
    get export() {
        return this.value;
    }
    toJSON() {
        return this.export;
    }
    setter(data: T) {
        this.value = data;
    }
}
export class Input<T = string> extends BasicSetting<T> {
    value: T;
    default: T;
    csshook: (arg: T) => T;
    useCss: boolean;
    options: Record<string, string | number>;
    constructor({
        name,
        value,
        csshook = (s) => s,
        path,
        useCss = false,
        options = {}
    }: {
        name?: string;
        value: T;
        csshook?: (arg: T) => T;
        path?: string;
        useCss?: boolean;
        options?: Record<string, string | number>;
    }) {
        super({ name, path });
        this.type = 'INP';
        this.value = value;
        this.default = value;
        this.csshook = csshook;
        this.useCss = useCss;
        this.options = options;
        this.setter(value);
    }
    get export() {
        return this.value;
    }
    toJSON() {
        return this.export;
    }
    setter(data: T) {
        if (typeof data !== 'string' || typeof data == 'undefined') data = this.default;
        this.value = String(data) as T;
    }
    cssValue() {
        return this.csshook ? this.csshook(this.value) : this.value;
    }
}

type LISTENER<T> = (newValue: T, prev: T) => void;
type EVENTS = 'before*' | '*';

export class Settings<T extends { [name: string]: BasicSetting }> extends Eventify {
    raw: { readonly [K in keyof T]: T[K] };
    proxy: InstanceType<typeof Proxy<{ [K in keyof T]: T[K]['value'] }>>;
    constructor(descriptions: T) {
        super();
        this.raw = descriptions;

        type keys = Extract<keyof T, string>;
        this.proxy = new Proxy(descriptions, {
            set: <PROP extends keys>(target: T, prop: PROP, newValue: T[PROP]['value']) => {
                const previous = target[prop].value;
                this.emit('before*', prop, newValue);
                target[prop].setter(newValue);
                try {
                    newValue !== previous && this.emit(prop, newValue, previous); // on set
                    newValue !== previous && this.emit('*', prop, newValue, previous); // on any
                } catch (message) {
                    console.error(message);
                }
                return true;
            },
            get<PROP extends keys>(target: T, prop: PROP) {
                return target[prop].value;
            }
        });
    }
    on<EVENT_NAME extends EVENTS | Extract<keyof T, string>, FN = LISTENER<T[EVENT_NAME]['value']>>(
        ...rest: [EVENT_NAME, ...EVENT_NAME[], FN]
    ): FN {
        return super.on(...rest);
    }
    removeListener<FN extends LISTENER<unknown>>(event_name: EVENTS | Exclude<keyof T, symbol>, fn: FN) {
        return super.removeListener(event_name, fn);
    }

    waitfor<C extends (resolve: () => void, reject: () => void) => () => void>(
        event_name: EVENTS | Exclude<keyof T, symbol>,
        callback: C
    ) {
        return super.waitfor(event_name, callback);
    }
    import(object: Record<string, ReturnType<(typeof JSON)['parse']>>) {
        if (!object) return;
        for (const option in this.raw) {
            if (this.raw.hasOwnProperty(option) && object.hasOwnProperty(option)) {
                this.proxy[option] = object[option];
            }
        }
    }
    export() {
        const export_data = {} as { [K in keyof T]: T[K]['value'] };
        for (const option in this.raw) {
            export_data[option] = this.raw[option].export;
        }
        return export_data;
    }
    restore() {
        for (const opt in this.raw) {
            const option: Extract<keyof T, string> = opt;
            if (this.raw.hasOwnProperty(option)) {
                this.proxy[option] = this.raw[option].default;
            }
        }
    }
    emit<EVENT_NAME extends EVENTS | Extract<keyof T, string>>(
        string: EVENT_NAME,
        ...rest: [T[EVENT_NAME]['value'], T[EVENT_NAME]['value']?, unknown?]
    ) {
        return super.emit(string, ...rest);
    }

    delegateTo<EVENT_NAME extends EVENTS | Extract<keyof T, string>, L extends LISTENER<T[EVENT_NAME]['value']>>(
        target: InstanceType<typeof Eventify>,
        ...rest: [...EVENT_NAME[], L]
    ): L {
        return super.delegateTo<L>(target, ...rest);
    }
}

export const settingsDescriptions = {
    input: new Input({ value: 'default' }),
    select: new Select({ options: { a: 'a', b: 'b' }, value: 'a' }),
    slider: new Slider({ min: 0, max: 100, step: 1, value: 50 }),
    soundsEnabled: new Option({ value: true, name: t('sound') }),
    musicEnabled: new Option({ value: true, name: t('music') }),
    animationEnabled: new Option({ value: true, name: t('animation') })
};

const settings = new Settings(settingsDescriptions);
settings.import(storage.get('settings'));
settings.on('*', () => {
    storage.set('settings', settings.export());
});

export { settings };

settings.on('input', (newValue, prev) => {
    //console.log('input', newValue, prev);
});
const e = new Eventify();
e.listenTo(settings, 'input', (newValue, prev) => {
    //console.log('input', newValue, prev);
});
