import { Dialog } from '@headlessui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { money } from '../../utils/utils';
import { appContext } from '../contexts';
import Button from '../other/Button';
import { DialogComponent, DialogContext } from '../other/Dialog';

export const ModalGameLimits = ({ state }: { state: [boolean, React.Dispatch<React.SetStateAction<boolean>>] }) => {
    return (
        <DialogComponent state={state}>
            <ModalContents />
        </DialogComponent>
    );
};

const ModalContents = () => {
    const { t } = useTranslation();
    const { close } = useContext(DialogContext);
    const app = useContext(appContext);
    //console.log('close', close);

    return (
        <div>
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 modal-header">
                {t('menu.gameLimits')}
            </Dialog.Title>

            <div className="p-4 pt-3">
                <table className="table-auto w-full">
                    {[
                        {
                            title: `${t('gameLimits.minimumBet')} ${app.config.bet.code}:`,
                            value: money(app.config.bet.min, app.config.bet)
                        },
                        {
                            title: `${t('gameLimits.maximumBet')} ${app.config.bet.code}:`,
                            value: money(app.config.bet.max, app.config.bet)
                        },
                        {
                            title: `${t('gameLimits.maximumWin')} ${app.config.bet.code}:`,
                            value: money(app.config.bet.maxWin, app.config.bet)
                        }
                    ].map(({ title, value }) => (
                        <tr>
                            <th className="font-normal">{title}</th>
                            <td className="text-right">
                                <span>{value}</span>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>

            <div className="bottom-bar">
                <Button onClick={close}>{t('gameLimits.gotItThanks')}</Button>
            </div>
        </div>
    );
};
