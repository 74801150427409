import { Tab } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AllBets } from './AllBets';
import { MyBetsContainer } from './MyBets';
import { TopBetsContainer } from './TopWins';
import './BetLog.scss';

export default function Betlog() {
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(0);

    const tabs = [
        { name: t('betlog.allBets'), component: <AllBets /> },
        { name: t('betlog.myBets'), component: <MyBetsContainer /> },
        { name: t('betlog.topBets'), component: <TopBetsContainer /> }
    ];

    return (
        <Tab.Group defaultIndex={0} onChange={(index) => setSelectedIndex(index)}>
            <div className={`flex w-full items-center justify-center`}>
                <div
                    className="relative flex items-center justify-center w-full space-x-1 h-8 bg-[#27272A] rounded-full m-2"
                    style={{ maxWidth: '400px' }}
                >
                    {/* Sliding Indicator */}
                    <div
                        className="absolute bg-[#3F3F46] rounded-full transition-all focus:outline-none duration-300 ring-1 ring-[#fefce81a]"
                        style={{
                            width: `${100 / tabs.length}%`,
                            transform: `translateX(${(selectedIndex-1) * 100}%)`,
                            height: '98%'
                        }}
                    />
                    <Tab.List className="flex w-full z-10">
                        {tabs.map((tab, idx) => (
                            <Tab
                                key={idx}
                                as="button"
                                className={({ selected }) =>
                                    `relative text-center justify-center focus:outline-none z-20 flex-1 text-sm font-medium rounded-full ${
                                        selected ? 'active-tab-item' : 'tab-item'
                                    }`
                                }
                            >
                                {tab.name}
                            </Tab>
                        ))}
                    </Tab.List>
                </div>
            </div>
            <Tab.Panels as={Fragment}>
                {tabs.map((tab, idx) => (
                    <Tab.Panel
                        key={idx}
                        id={`tab-panel-${idx}`}
                        className="flex flex-col flex-grow overflow-y-hidden"
                        style={{ minHeight: '320px' }}
                    >
                        {tab.component}
                    </Tab.Panel>
                ))}
            </Tab.Panels>
        </Tab.Group>
    );
}
