import Decimal from 'decimal.js-light';

export default class {
    maxWin: number;
    minWin: number;
    accuracy: number;
    d_x = 0; // range of acceptable values
    multiplier = 0; // final multiplier
    a = new Decimal(1.086); // interpolation of the multiplier

    constructor(maxMult: number, minMult = 0.96) {
        this.maxWin = maxMult; // Maximum winnings in bets
        this.minWin = minMult; // Minimum winnings in bets
        this.accuracy = 10000000000;
    }

    randomRange() {
        this.d_x = range(this.accuracy / this.minWin, this.accuracy / this.maxWin);
    }

    // calcMultiplier() {
    //     this.multiplier = parseFloat((this.accuracy / this.d_x).toFixed(2));
    //     return this.multiplier;
    // }

    interpolateMultiplier(msElapsed: Decimal) {
        const nextMultiplier = this.a.pow(msElapsed.div(1000)); // .toDecimalPlaces(10, Decimal.ROUND_FLOOR); //  Math.pow(this.a, msElapsed / 1000);

        return nextMultiplier;
    }

    /**
     * @returns Time in milliseconds
     */
    calcTimeForMultiplier(targetMultiplier: number) {
        if (targetMultiplier <= 0)
            throw new Error(`Target multiplier must be a positive number (targetMultiplier: ${targetMultiplier})`);

        if (targetMultiplier < this.minWin) {
            throw new Error(
                `Target multiplier must be greater than 1 (targetMultiplier: ${targetMultiplier}, minWin: ${this.minWin})`
            );
            debugger;
            // The multiplier will never be less than this.minWin
        }

        return new Decimal(targetMultiplier).log().div(this.a.log()).mul(1000);
    }
}

const range = (min: number, max: number): number => Math.random() * (max - min) + min;
