import { useContext, useState } from 'react';
import Menu from '../Menu/Menu';
import { PingIndicator } from '../PingIndicator';
import { appContext } from '../contexts';
import { Money } from '../utils';

export default function Header() {
    const state = useState(true);
    // const [ping, setPing] = useState(0);

    const app = useContext(appContext);
    app;

    return (
        <>
            {/*
                <DialogComponent state={state}>
                    <ModalRoundInfo />
                </DialogComponent>
            <div className="fixed w-full flex flex-row items-center h-10 z-50 panel-bg">
            */}
            <div className="w-full flex flex-row items-center h-8 z-50 panel-bg">
                <div className="flex w-full justify-between">
                    <div className="flex flex-row items-center px-2">
                        {/*
                        <HowToPlayButton />
                        */}
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <div className="text-sm">
                            <PingIndicator />
                        </div>
                        <div className="font-bold text-[#16a34a] ">
                            <Money />
                        </div>
                        <Menu />
                    </div>
                </div>
            </div>
        </>
    );
}
