import Decimal from 'decimal.js-light';
import gsap from 'gsap';
import { BitmapFont, BitmapText, Container, Graphics, Sprite, Text, TextStyle, Texture, Ticker } from 'pixi.js';
import { Eventify } from '../../../../Shared/Eventify';
import { RoundState, SrvState } from '../../../../Shared/Types';
import Bet from '../../Bet';
import { AppEvents } from '../../Types';
import { config } from '../../config';
import { vh, vw } from '../../utils/dimensions';
import {
    EasingFunctions,
    getCurrencySymbol,
    grid_util,
    interpolateHexColorsWithStrength,
    valueToScale,
    valueToScaleClamped
} from '../../utils/utils';
import Visualisation from '../Visualisation';

const TEXT_MULTIPLIER_FONTNAME = 'multiplierfont';
const TEXT_BASE_MULTIPLIER_FONTNAME = 'basemultiplierfont';
const TEXT_PROFIT_FONTNAME = 'profitfont';
const PROGRESS_BAR_WIDTH = 300;
const LOADER_COLOR = 0xffda00;
export default class Foreground extends Eventify {
    container = new Container();
    containerCenter = this.container.addChild(new Container());
    containerProfits = this.containerCenter.addChild(new Container<BitmapText>());

    styleTextMultiplier!: TextStyle;
    fontTextMultiplier!: BitmapFont;

    baseStyleMultiplier!: TextStyle;
    fontBaseMultiplier!: BitmapFont;

    styleTextProfit!: TextStyle;
    fontTextProfit!: BitmapFont;

    textMultiplier!: BitmapText;
    baseMultiplier!: BitmapText;
    animBlink!: gsap.core.Timeline;

    textFlewAway!: Text;
    baseFlewAway!: Text;
    gradientFlewAway!: Sprite;
    colors: Array<[number, number]>;

    lastMultiplier = 0;

    loaderTexture!: Texture;
    loaderSprite!: Sprite;
    loaderText!: Text;

    progressBarGraphics!: Graphics;
    progressBarProgression = 100;

    loaderStartTime!: Date;
    loaderOffsetY: number = 0;
    loaderContainer: Container = new Container();

    scale: number = 1;
    previousTime = Date.now();

    constructor(
        public stage: Visualisation,
        private t: (key: string) => string
    ) {
        super();

        this.updateScale();

        this.loaderInit();
        this.initFonts();
        this.drawResources();
        this.initAnims();
        this.updProfits();

        this.colors = Object.entries(config.multiplierColors)
            .map((v) => v.map(Number))
            .sort((a, b) => a[0] - b[0]) as Array<[number, number]>;

        this.listenTo(stage, Visualisation.Events.tick, this.onTick);
        this.listenTo(stage.app.state, 'roundState', 'serverState', this.onStateChange)();
        this.listenTo(stage, Visualisation.Events.resize, this.resize);
    }
    initFonts() {
        // Multiplier
        this.baseStyleMultiplier = new TextStyle({
            fontFamily: 'Roboto-Bold',
            fontSize: 56,
            fill: [0xfefce826, 0x2c2d3026],
            align: 'center',
            lineJoin: 'round'
        });

        this.fontBaseMultiplier = BitmapFont.from(TEXT_BASE_MULTIPLIER_FONTNAME, this.baseStyleMultiplier, {
            chars: [['0', '9'], 'x.'],
            resolution: 3
        });

        this.styleTextMultiplier = new TextStyle({
            fontFamily: 'Roboto-Bold',
            fontSize: 56,
            fill: 0xffffff,
            align: 'center',
            lineJoin: 'round',
            stroke: 0xfefce866,
            strokeThickness: 0.5
        });

        this.fontTextMultiplier = BitmapFont.from(TEXT_MULTIPLIER_FONTNAME, this.styleTextMultiplier, {
            chars: [['0', '9'], 'x.'],
            resolution: 3
        });

        // Profits
        this.styleTextProfit = new TextStyle({
            fontFamily: 'Roboto-Bold',
            fontSize: 22,
            fill: 0xffda00,
            align: 'center',
            lineJoin: 'round',
            dropShadow: true,
            dropShadowAlpha: 0.5,
            dropShadowColor: 0x101010,
            dropShadowDistance: 1
        });

        this.listenTo(this.stage.app, AppEvents.updateConfig, () => {
            const currencyCode = this.stage.app.config.bet.code;
            this.fontTextProfit = BitmapFont.from(TEXT_PROFIT_FONTNAME, this.styleTextProfit, {
                chars: [['0', '9'], '=+., ', ...BitmapFont.ALPHA, currencyCode, getCurrencySymbol(currencyCode)],
                resolution: 5
            });
        })();
    }
    async loaderInit() {
        this.loaderTexture = await Texture.from('./assets/sprites/loader.svg', {
            resourceOptions: {
                scale: 1
            }
        });

        this.loaderSprite = new Sprite(this.loaderTexture);
        this.loaderSprite.anchor.set(0.5, 0.5);

        // progress line
        this.progressBarGraphics = new Graphics();

        // progress line text
        this.loaderText = new Text('Waiting for the next round!', {
            fontFamily: 'Roboto-Regular',
            fontSize: 50,
            fill: 0xfefce8,
            align: 'center'
        });
        this.loaderText.anchor.set(0.5);

        this.loaderContainer.addChild(this.progressBarGraphics, this.loaderSprite, this.loaderText);
        this.container.addChild(this.loaderContainer);

        this.drawLoader();
    }
    drawLoader() {
        if (!this.loaderSprite) return;

        const loaderSpriteHeight = this.loaderSprite.height;

        this.loaderSprite.scale.set(this.scale * 0.25);

        // TODO try to find a better way to calculate the offset
        // TODO this is hotfix for the init - 400 is svg default height
        const scaledInitialHeight = 400 * this.scale * 0.25;
        this.loaderOffsetY = (loaderSpriteHeight === 1 ? scaledInitialHeight : this.loaderSprite.height) / 5;
        this.loaderSprite.position.set(this.stage.width / 2, this.stage.height / 2 - this.loaderOffsetY);

        this.loaderText.scale.set(this.scale * 0.25);
        this.loaderText.position.set(
            this.stage.width / 2,
            this.stage.height / 2 + this.loaderOffsetY + this.loaderText.height + 15 * 0.25 * this.scale
        );
    }
    drawProgressBar() {
        // Possible optimization would be caching the dark background and only updating the yellow part
        // But it's not necessary for this use case at the moment since the animation performance is still good
        const lineScale = this.scale * 0.5;
        const scaledWidth = PROGRESS_BAR_WIDTH * lineScale;

        const lineX = this.stage.width / 2 - scaledWidth / 2;
        const lineY = this.stage.height / 2 + this.loaderOffsetY + this.loaderText.height + 60 * 0.25 * this.scale;
        this.progressBarGraphics.clear();

        this.progressBarGraphics.beginFill(0x18181b, 1);
        this.progressBarGraphics.drawRoundedRect(lineX, lineY, scaledWidth, 10, 100);
        this.progressBarGraphics.endFill();

        this.progressBarGraphics.beginFill(LOADER_COLOR, 1);
        this.progressBarGraphics.drawRoundedRect(
            lineX + 1,
            lineY + 1,
            ((scaledWidth - 2) * this.progressBarProgression) / 100,
            8,
            100
        );
    }
    loaderTick() {
        // animation above the progress bar
        const rotationSpeed = 5;
        const currentTime = Date.now();
        const elapsedTime = (currentTime - this.previousTime) / 1000;
        this.loaderSprite.rotation += rotationSpeed * elapsedTime;
        this.previousTime = currentTime;

        // progress bar
        if (this.stage.app.state.roundState === RoundState.INIT || this.stage.app.realtimeMultiplier === Infinity) {
            this.drawProgressBar();
            return;
        }

        // This is hotfix since BE is sending wrong betEndTime that is only 2 seconds after betOpenTime
        this.progressBarProgression =
            EasingFunctions.linear(
                valueToScale(Date.now(), this.stage.app.betOpenTime + 5000, this.stage.app.betOpenTime)
            ) * 100;
        // this.progressBarProgression = EasingFunctions.linear(valueToScale(Date.now(), this.stage.app.betEndTime, this.stage.app.betOpenTime)) * 100;
        this.progressBarProgression = Math.max(0, this.progressBarProgression);
        this.drawProgressBar();
    }
    createGradientTexture = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 200;
        canvas.height = 50;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return Texture.EMPTY;
        }
        const gradient = ctx.createLinearGradient(0, 0, 50, 0);
        gradient.addColorStop(0, 'rgba(77, 77, 77, 0)');
        gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.6)');
        gradient.addColorStop(1, 'rgba(77, 77, 77, 0)');

        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, 50, 100);

        return Texture.from(canvas);
    };

    drawResources() {
        this.textMultiplier = new BitmapText('xxx', { fontName: TEXT_MULTIPLIER_FONTNAME });
        this.baseMultiplier = new BitmapText('xxx', { fontName: TEXT_BASE_MULTIPLIER_FONTNAME });
        this.textMultiplier.anchor.set(0.5);
        this.baseMultiplier.anchor.set(0.5);
        this.containerCenter.addChild(this.textMultiplier);
        this.containerCenter.addChild(this.baseMultiplier);

        this.gradientFlewAway = new Sprite(this.createGradientTexture());
        this.gradientFlewAway.anchor.set(0.5);
        this.textFlewAway = new Text(this.t('plot.flewAway'), {
            fontFamily: 'Roboto-Regular',
            fontSize: 100,
            fill: 0xfefce8,
            align: 'center'
        });
        this.textFlewAway.roundPixels = true;
        this.textFlewAway.anchor.set(0.5);
        this.containerCenter.addChild(this.textFlewAway);
        this.containerCenter.addChild(this.gradientFlewAway);

        this.baseFlewAway = new Text(this.t('plot.flewAway'), {
            fontFamily: 'Roboto-Regular',
            fontSize: 100,
            fill: 0xfefce8,
            align: 'center'
        });
        this.baseFlewAway.roundPixels = true;
        this.baseFlewAway.anchor.set(0.5);
        this.containerCenter.addChild(this.baseFlewAway);
        this.gradientFlewAway.mask = this.baseFlewAway;

        Ticker.shared.add(() => {
            this.gradientFlewAway.x += 1;
            if (this.gradientFlewAway.x > this.textFlewAway.width) {
                this.gradientFlewAway.x = -this.textFlewAway.width;
            }
        });
    }
    isBetVisible = (bet: Bet) => bet.state.setted || bet.state.cashedOut;

    updProfits() {
        const currentSize = this.containerProfits.children.length;
        const targetSize = this.stage.app.bets.size;

        for (let i = currentSize; i < targetSize; i++) {
            const text = new BitmapText('+ 0.0001', { fontName: TEXT_PROFIT_FONTNAME });
            text.anchor.set(0.5);
            this.containerProfits.addChild(text);
        }

        for (let i = targetSize; i < currentSize; i++) {
            this.containerProfits.removeChildAt(i);
        }

        const profitsVisible =
            this.stage.app.state.roundState === RoundState.ROUND_STARTED ||
            this.stage.app.state.roundState === RoundState.ROUND_ENDED;

        let i = 0;
        for (const bet of this.stage.app.bets) {
            const text = this.containerProfits.children[i];
            text.visible = this.isBetVisible(bet) && profitsVisible;
            i++;
        }

        const betIterator = this.stage.app.bets.values();

        grid_util(
            this.containerProfits.children.filter((t) => t.visible),
            1,
            0,
            0,
            0,
            30,
            0.5,
            0,
            (elem, x, y) => {
                let bet = betIterator.next().value as Bet;

                if (!this.isBetVisible(bet)) {
                    bet = betIterator.next().value as Bet;
                }

                elem.position.set(x, y);
                const text = bet.state.cashedOut ? `${this.t('plot.youWon')} =` : '+';
                elem.text = text + ' ' + bet.getCashOutValueFormatted();
            }
        );
    }

    initAnims() {
        const anim0 = () => {
            gsap.to(this.textMultiplier.scale, {
                x: 1,
                y: 1,
                duration: 0.02
            });
            gsap.to(this.baseMultiplier.scale, {
                x: 1.1,
                y: 1.1,
                duration: 0.08
            });
        };
        const anim1 = () => {
            gsap.to(this.textMultiplier.scale, {
                x: 1.1,
                y: 1.1,
                duration: 0.02
            });
            gsap.to(this.baseMultiplier.scale, {
                x: 1,
                y: 1,
                duration: 0.08
            });
        };
        const anim2 = () => {
            gsap.to(this.textMultiplier.scale, {
                x: 1,
                y: 1,
                duration: 0.08
            });
            gsap.to(this.baseMultiplier.scale, {
                x: 1,
                y: 1,
                duration: 0.08
            });
        };
        this.animBlink = gsap
            .timeline({
                paused: true,
                yoyo: false,
                repeat: 0
            })
            .add(anim0, 0)
            .add(anim1, '>')
            .add(anim2, '>');
    }
    onTick = () => {
        if (this.loaderContainer.visible) {
            this.loaderTick();
        }
        if (this.stage.app.realtimeState.multiplier <= 0 || isNaN(this.stage.app.realtimeState.multiplier)) {
            this.textMultiplier.visible = false;
            return;
        } else if (this.stage.app.state.roundState === RoundState.ROUND_STARTED && !this.textMultiplier.visible) {
            this.textMultiplier.visible = true;
        }
        const rawMultiplier = this.stage.app.realtimeState.multiplier;

        this.textMultiplier.text = Number.isFinite(rawMultiplier)
            ? new Decimal(rawMultiplier || 0).toDecimalPlaces(2, Decimal.ROUND_FLOOR).toFixed(2) + 'x'
            : '0.00x';

        this.baseMultiplier.text = this.textMultiplier.text;

        const factor = valueToScaleClamped(this.stage.app.realtimeState.multiplier, 1, 1000);
        this.textMultiplier.tint = interpolateHexColorsWithStrength(this.colors, factor);

        if (
            this.lastMultiplier !== ~~this.stage.app.realtimeState.multiplier &&
            this.stage.app.realtimeState.multiplier > 1
        ) {
            this.lastMultiplier = ~~this.stage.app.realtimeState.multiplier;
            this.animBlink.restart();
            this.animBlink.play();
        }

        this.updProfits();
    };
    onStateChange = () => {
        this.textFlewAway.visible =
            this.stage.app.state.roundState === RoundState.ROUND_ENDED &&
            this.stage.app.state.serverState === SrvState.STOPPED;

        this.baseFlewAway.visible = this.textFlewAway.visible;
        this.gradientFlewAway.visible = this.textFlewAway.visible;

        this.textMultiplier.visible =
            this.stage.app.state.roundState === RoundState.ROUND_STARTED ||
            this.stage.app.state.roundState === RoundState.ROUND_ENDED;

        this.baseMultiplier.visible = this.textMultiplier.visible;

        const isLoaderVisible =
            this.stage.app.state.roundState === RoundState.BET_OPENED ||
            this.stage.app.state.roundState === RoundState.INIT;
        if (isLoaderVisible) {
            this.loaderContainer.visible = true;
            this.progressBarProgression = 100;
        } else {
            this.loaderContainer.visible = false;
        }
    };
    updateScale() {
        const w = 6;
        const h = 19;
        const f = 0.35;
        this.scale =
            this.stage.size.width / this.stage.size.height > w / h
                ? vh(f, this.stage.size.height)
                : (h * vw(f, this.stage.size.width)) / w;
    }
    resize = () => {
        this.updateScale();

        grid_util(
            [this.textFlewAway, this.textMultiplier, this.containerProfits],
            1,
            0,
            0,
            0,
            40,
            0.5,
            0.5,
            (elem, x, y) => {
                elem.position.set(x, y);
            }
        );

        this.baseFlewAway.position.set(this.textFlewAway.x, this.textFlewAway.y);

        this.gradientFlewAway.position.set(this.textFlewAway.x, this.textFlewAway.y);

        this.textFlewAway.scale.set(1 / 3);
        this.baseFlewAway.scale.set(1 / 3);
        this.containerCenter.position.set(this.stage.size.width / 2, this.stage.size.height / 2);
        this.containerCenter.scale.set(this.scale);

        this.drawLoader();
    };
}
