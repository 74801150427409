import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalProvablyFair from '../Modals/ModalProvablyFair';
import { DialogComponent } from '../other/Dialog';

export const Footer: React.FC<{}> = () => {
    const state = useState(false);
    const { t } = useTranslation();

    const [_isHelpOpen, setHelpOpen] = state;

    return (
        <>
            <div className="flex px-2 py-1 w-full place-content-between bg-slate-950 text-gray-500 whitespace-nowrap">
                {/**
                <div className="flex flex-row flex-nowrap  gap-1 items-center">
                    <div className="text-xs">{t('footer.provablyText')}</div>
                    <button className="text-sm text-gray-300" onClick={setHelpOpen.bind(null, true)}>
                        🔰Provably Fair
                    </button>
                </div>
                 */}
                <div className="flex flex-row gap-1 items-center">
                    <div className="text-xs">{t('footer.poweredBy')}</div>
                    <a
                        className="flex flex-nowrap gap-1 items-center text-sm text-gray-300"
                        href="https://endorphina.com/"
                        target="_blank"
                    >
                        {/*<img src="./assets/sprites/poweredby.png" className="w-4 h-4" />*/} Endorphina
                    </a>
                </div>
            </div>
            <DialogComponent state={state}>
                <ModalProvablyFair></ModalProvablyFair>
            </DialogComponent>
        </>
    );
};
