import {
    BaseTexture,
    BitmapFont,
    IAddOptions,
    LoaderResource,
    Rectangle,
    Loader as _Loader,
    Texture as _Texture,
    utils
} from 'pixi.js';
import { config } from '../config';
import { ANIMATIONS, ATLASES, BITMAP_FONTS, FONTS, IMAGES, MULTI_JSON_ASSETS, SPRITESHEETS } from './assets';
import { core } from './core/core';
import {
    IAtlasResourceBase,
    IAtlasResources,
    IBitmapFontResources,
    IImageResourceBase,
    IImageResources,
    IResource,
    ISpineResourceBase,
    ISpineResources,
    ResourceType
} from './Models/AssetsModel';
import { TICK_COLOR } from './Plot';

class Loader {
    loader = _Loader.shared;
    loadedAtlasesForMultSpine: Array<{ atlas: string; images: string[] }> = [];

    public get resources(): _Loader['resources'] {
        return this.loader.resources;
    }

    private getResource = (key: string): LoaderResource | _Texture => {
        const resource = this.resources[key];

        if (!resource) throw new Error(`There is no resource with name - {${key}}`);

        return resource;
    };

    getTexture = (key: string): _Texture => {
        const data = this.getResource(key);
        let texture: _Texture;

        if (data instanceof _Texture) {
            texture = data;
        } else {
            texture = data.texture!;
        }

        if (!texture) throw new Error(`There is no texture with name - {${key}}`);

        return texture;
    };

    getSprite = (key: string): core.Sprite => {
        const texture = this.getTexture(key);
        return new core.Sprite(texture);
    };

    addResources = (type: ResourceType) => {
        const toLoad = [ANIMATIONS, IMAGES, ATLASES, BITMAP_FONTS, SPRITESHEETS];

        const addFromList = (
            list: ISpineResources | IImageResources | IAtlasResources | IBitmapFontResources,
            assetsType: ResourceType
        ) => {
            list[assetsType]?.forEach((asset) => {
                const path = asset.path;

                if (this.loader.resources[this.getResourceKey(asset)]) {
                    //console.log(`Resource ${this.getResourceKey(asset)} already loaded, skipping.`);
                    return;
                }

                if (list === ANIMATIONS) {
                    const scale = (asset as ISpineResourceBase).spineScale || 1;
                    this.loader.add(this.getResourceKey(asset), config.assetsLocation + list.defaultPath + path, {
                        metadata: {
                            spineSkeletonScale: scale
                        }
                    } as IAddOptions);
                    Object.assign(this.loader.resources[this.getResourceKey(asset)], {
                        loadedScale: scale
                    });
                } else {
                    this.loader.add(this.getResourceKey(asset), config.assetsLocation + list.defaultPath + path);
                }
            });
        };

        toLoad.forEach((assetList) => {
            addFromList(assetList, type);
        });

        this.addMultiJsonAssets(type);
    };

    private getResourceKey = (asset: IImageResourceBase | IResource | ISpineResourceBase | IAtlasResourceBase) => {
        return (asset as IImageResourceBase)?.key || asset.path;
    };

    addMultiJsonAssets = (type: ResourceType) => {
        if (type === 'preload') {
            MULTI_JSON_ASSETS.main?.forEach((asset) => {
                const getAtlasName = () => {
                    const rawName = asset.atlas.split('.');
                    return rawName[rawName.length - 2];
                };

                const atlasPath = asset.folderPath + asset.atlas;
                const atlasName = getAtlasName();

                this.loader.add(atlasName, `${config.assetsLocation}${MULTI_JSON_ASSETS.defaultPath}${atlasPath}`, {
                    metadata: {
                        xhrType: 'text'
                    }
                } as IAddOptions);

                asset.images.forEach((image) => {
                    const path = image;

                    this.loader.add(
                        image,
                        `${config.assetsLocation}${MULTI_JSON_ASSETS.defaultPath}${asset.folderPath}${path}`
                    );
                });

                this.loadedAtlasesForMultSpine.push({ atlas: atlasName, images: asset.images });
            });
        } else {
            MULTI_JSON_ASSETS.main?.forEach((asset, index) => {
                const entries = Object.entries(asset.jsons);

                const atlas = (
                    ResourceController.getResource(this.loadedAtlasesForMultSpine[index].atlas) as LoaderResource
                ).data;

                const images: { [key: string]: BaseTexture } = {};

                this.loadedAtlasesForMultSpine[index].images.forEach((asset) => {
                    images[asset] = (ResourceController.getResource(asset) as LoaderResource).texture!.baseTexture;
                });

                entries.forEach((spine) => {
                    const scale = asset.scales?.[spine[0]] || 1;

                    this.loader.add(
                        spine[0],
                        `${config.assetsLocation}${MULTI_JSON_ASSETS.defaultPath}${asset.folderPath}${spine[1]}`,
                        {
                            metadata: {
                                atlasRawData: atlas,
                                images: images,
                                spineSkeletonScale: scale
                            }
                        } as IAddOptions
                    );

                    Object.assign(this.loader.resources[spine[0]], { loadedScale: scale });
                });
            });
        }
    };

    loadResources = (type: ResourceType) => {
        return new Promise<void>((resolve) => {
            const originalWarn = console.warn;
            const originalGroupCollapsed = console.groupCollapsed;

            console.warn = () => {};
            console.groupCollapsed = () => {};

            this.loader.load(() => {
                console.warn = originalWarn;
                console.groupCollapsed = originalGroupCollapsed;

                if (ATLASES[type]) {
                    for (const atlas of ATLASES[type]!) {
                        Object.assign(
                            ResourceController.resources,
                            ResourceController.resources[this.getResourceKey(atlas)].textures
                        );
                    }
                }

                resolve();
            });
        });
    };

    loadFonts = (type: ResourceType) => {
        const newStyle = document.createElement('style');

        FONTS[type]?.forEach((font) => {
            const extensionRaw = font.path.match(/\.[0-9a-z]+$/i);

            if (!extensionRaw) {
                console.error(`Font ${font.key} has no type`);
            }

            const extension = extensionRaw![0].slice(1);

            let fontCssFormat = '';

            switch (extension) {
                case 'ttf':
                    fontCssFormat = 'truetype';
                    break;
                case 'otf':
                    fontCssFormat = 'opentype';
                    break;
                case 'woff2': // ! Check on IOS Chrome / Safari / Mac
                    fontCssFormat = 'woff2';
                    break;
                default:
                    console.error(`Incorrect type of font ${font.key}`);
                    break;
            }

            newStyle.appendChild(
                document.createTextNode(
                    `@font-face {
                        font-family: "${font.key}";
                        src: url("./assets/${FONTS.defaultPath}${font.path}") format("${fontCssFormat}");
                    }`
                )
            );

            const div = document.createElement('div');
            div.innerHTML = '.123abcABCабв';
            div.style.fontFamily = font.key;
            div.style.opacity = '0';
            div.style.position = 'absolute';
            div.style.margin = '0';
            div.style.padding = '0';
            div.style.top = '0';
            div.style.zIndex = '-1';
            document.body.appendChild(div);
        });

        document.head.appendChild(newStyle);
        const isMobile = utils.isMobile.any;
        // create bitmap fonts
        BitmapFont.from(
            'RobotoMarker',
            {
                fontFamily: 'Roboto-Regular',
                fontSize: 25,
                fontWeight: '800',
                fill: 16777215,
                stroke: 0x000000,
                strokeThickness: isMobile ? 0.5 : 2,
                miterLimit: 1
            },
            {
                chars: [['0', '9'], 'x.'],
                resolution: isMobile ? 3 : 2
            }
        );

        BitmapFont.from(
            'AxisFont',
            {
                fontFamily: 'Arial',
                fontSize: 12,
                fontWeight: '500',
                fill: TICK_COLOR,
                // stroke: 0x000000,
                strokeThickness: isMobile ? 0.5 : 2,
                miterLimit: 1
            },
            {
                chars: [['0', '9'], 'sx.'],
                resolution: isMobile ? 3 : 2
            }
        );

        BitmapFont.from(
            'RobotoCashOutMultiplier',
            {
                fontFamily: 'Roboto-Regular',
                fontSize: 25,
                fontWeight: '800',
                fill: 0xffffff,
                stroke: 0xffffff,
                strokeThickness: isMobile ? 0.5 : 1
            },
            {
                chars: [['0', '9'], 'x.'],
                resolution: isMobile ? 3 : 2
            }
        );

        BitmapFont.from(
            'RobotoCashOutAmount',
            {
                fontFamily: 'Roboto-Regular',
                fontSize: 25,
                fontWeight: '400',
                fill: 0x5da955,
                stroke: 0x000000,
                strokeThickness: isMobile ? 0.5 : 1
            },
            {
                chars: [['0', '9'], ['A', 'Z'], ['a', 'z'], '.'],
                resolution: isMobile ? 3 : 2
            }
        );

        return document.fonts.ready;
    };

    applyCostyli = () => {
        const trimWithFrame = (frac: number, texture: _Texture) => {
            texture.frame = new Rectangle(
                texture.frame.x + frac,
                texture.frame.y + frac,
                texture.frame.width - frac * 2,
                texture.frame.height - frac * 2
            );

            texture.updateUvs();
        };
    };
}

export const ResourceController = new Loader();
